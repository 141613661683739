import {Component, Input, ViewChild} from '@angular/core';
import {AppNotificationFrom} from "../../notification.component";
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {RouterModule} from "@angular/router";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {
  INotificationsNewPostCommentNotification
} from "desiren-core-lib/lib/types/notifications/content/new-post-comment.notification.interface";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-n-new-post-comment',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    RouterModule,
    IconsComponent,
    UserNameComponent,
    TranslocoModule,
  ],
  templateUrl: './n-new-post-comment.component.html',
  styleUrl: './n-new-post-comment.component.scss'
})
export class NNewPostCommentComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;
  @Input('data') declare data: INotificationsNewPostCommentNotification;

  get link() {
    return `/profile/${this.myId}/post/${this.data.postId}`;
  }
}
