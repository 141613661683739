import {Component, Input} from '@angular/core';
import {AppNotificationFrom} from "../../notification.component";
import {
  INotificationsInPostTagNotification
} from "desiren-core-lib/lib/types/notifications/tags/in-post-tag.notification.interface";

@Component({
  selector: 'app-n-in-post-tag',
  standalone: true,
  imports: [],
  templateUrl: './n-in-post-tag.component.html',
  styleUrl: './n-in-post-tag.component.scss'
})
export class NInPostTagComponent extends AppNotificationFrom {
@Input('data') declare data: INotificationsInPostTagNotification;
}
